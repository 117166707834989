<template>
  <div
    style="
      background-color: rgb(235, 245, 250);
      padding-top: 8px;
      padding-bottom: 30px;
    "
  >
    <div style="padding: 10px 15px">
      <div
        style="
          text-align: left;
          background-color: white;
          padding: 8px 15px;
          border-radius: 5px;
        "
      >
        <span style="font-size: 12px; font-weight: bold; margin-right: 8px"
          >报表分析</span
        >
        <DatePicker
          type="daterange"
          show-week-numbers
          :options="dateOpts"
          placeholder="选择日期范围"
          format="yyyy-MM-dd"
          :value="tmval"
          @on-change="dateChangeHandle"
        ></DatePicker>

        <!-- <button @click="doclick">click</button> -->
      </div>
    </div>

    <div style="overflow: hidden; padding: 10px 15px">
      <Card style="width: 230px; float: left">
        <div>
          <div style="overflow: hidden">
            <span style="float: left">告警量</span>
            <!-- <Icon style="float: right" type="ios-help-circle" /> -->
          </div>
          <h1 style="text-align: left; padding: 15px">0</h1>
          <div style="text-align: left; font-size: 0.8em">
            周期增加 <span>0</span>
          </div>
        </div>
      </Card>

      <Card style="width: 230px; float: left; margin-left: 20px">
        <div>
          <div style="overflow: hidden">
            <span style="float: left">平均持续时间</span>
            <!-- <Icon style="float: right" type="ios-help-circle" /> -->
          </div>
          <h1 style="text-align: left; padding: 15px">0</h1>
          <div style="text-align: left; font-size: 0.8em">
            周期增加 <span>0</span>
          </div>
        </div>
      </Card>

      <Card style="width: 230px; float: left; margin-left: 20px">
        <div>
          <div style="overflow: hidden">
            <span style="float: left">未恢复</span>
            <!-- <Icon style="float: right" type="ios-help-circle" /> -->
          </div>
          <h1 style="text-align: left; padding: 15px">0</h1>
          <div style="text-align: left; font-size: 0.8em">
            周期增加 <span>0</span>
          </div>
        </div>
      </Card>
    </div>

    <div style="overflow: hidden; padding: 10px 0">
      <div
        style="
          width: 48%;
          background-color: white;
          padding: 10px 15px;
          float: left;
          margin-left: 1%;
        "
      >
        <h4 style="padding: 15px 0; text-align: left">Top告警</h4>
        <Table
          height="280"
          border
          size="small"
          :columns="topAlertColumns"
          :data="topAlerts"
        >
        </Table>
      </div>

      <div
        style="
          width: 48%;
          background-color: white;
          padding: 10px 15px;
          float: right;
          margin-right: 1%;
        "
      >
        <h4 style="padding: 15px 0; text-align: left">Top业务</h4>
        <Table
          height="280"
          border
          size="small"
          :columns="topBusinessColumns"
          :data="topBusiness"
        >
        </Table>
      </div>
    </div>

    <div style="overflow: hidden; padding: 10px 0">
      <div
        style="
          width: 48%;
          background-color: white;
          padding: 10px 15px;
          float: left;
          margin-left: 1%;
        "
      >
        <h4 style="padding: 15px 0; text-align: left">Top机器</h4>
        <Table
          height="280"
          border
          size="small"
          :columns="topObjectColumns"
          :data="topObjects"
        >
        </Table>
      </div>

      <div
        style="
          width: 48%;
          background-color: white;
          padding: 10px 15px;
          float: right;
          margin-right: 1%;
        "
      >
        <Tabs value="p1">
          <TabPane label="今日新事件" name="p1">
            <Table
              height="280"
              border
              size="small"
              :columns="newAlertsColumns"
              :data="todayAlerts"
            >
            </Table>
          </TabPane>

          <TabPane label="本周新事件" name="p2">
            <Table
              height="280"
              border
              size="small"
              :columns="newAlertsColumns"
              :data="todayAlerts"
            >
            </Table>
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, del } from "@/api/http.js";
export default {
  name: "inhibits",
  data() {
    return {
      tmval: this.getCurrentDateRange(),
      dateOpts: {
        shortcuts: [
          {
            text: "近一天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              return [start, end];
            },
          },
          {
            text: "近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "近30天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
        ],
      },
      severitynum: {
        0: "P0",
        1: "P1",
        2: "P2",
        3: "P3",
        4: "P4",
        5: "P5",
      },
      todayAlerts: [
        {
          id: "767643984454",
          fields: {
            pp_alert_name: "内存过高",
            pp_object: "87fhgtfre5",
            pp_severity: 0,
            pp_start_time: 1638949061,
            pp_alert_status: "firing",
            pp_business: "快手",
          },
        },
      ],
      newAlertsColumns: [
        {
          title: "告警名称",
          key: "pp_alert_name",
          minWidth: 170,
          render: (h, { row }) => {
            return h("div", this.get_pp_alert_name(row.fields));
          },
        },
        {
          title: "告警对象",
          key: "pp_object",
          minWidth: 120,
          render: (h, { row }) => {
            return h("div", this.get_pp_object(row.fields));
          },
        },
        {
          title: "等级",
          key: "pp_severity",
          minWidth: 60,
          render: (h, { row }) => {
            return h("div", this.get_pp_severity(row.fields));
          },
        },
        {
          title: "发生时间",
          key: "pp_start_time",
          minWidth: 100,
          render: (h, { row }) => {
            return h("div", this.get_pp_start_time(row.fields));
          },
        },
        {
          title: "状态",
          key: "pp_alert_status",
          minWidth: 80,
          render: (h, { row }) => {
            return h("div", this.get_pp_alert_status(row.fields));
          },
        },
        {
          title: "业务",
          key: "pp_business",
          minWidth: 100,
          render: (h, { row }) => {
            return h("div", this.get_pp_business(row.fields));
          },
        },
      ],
      topObjects: [
        {
          object_name: "dfduh776654j",
          count: "20",
        },
        {
          object_name: "xxx",
          count: "30",
        },
        {
          object_name: "dfduh776654j",
          count: "20",
        },
        {
          object_name: "xxx",
          count: "30",
        },
        {
          object_name: "dfduh776654j",
          count: "20",
        },
        {
          object_name: "xxx",
          count: "30",
        },
        {
          object_name: "dfduh776654j",
          count: "20",
        },
        {
          object_name: "xxx",
          count: "30",
        },
        {
          object_name: "dfduh776654j",
          count: "20",
        },
        {
          object_name: "xxx",
          count: "30",
        },
      ],
      topObjectColumns: [
        {
          type: "index",
          title: "排名",
          width: 70,
          aligh: "center",
        },
        {
          title: "机器ID",
          key: "object_name",
          minWidth: 100,
        },
        {
          title: "告警数量",
          key: "count",
          width: 100,
        },
      ],
      topBusiness: [
        {
          business_name: "快手",
          count: "20",
        },
        {
          business_name: "爱奇艺",
          count: "30",
        },
      ],
      topBusinessColumns: [
        {
          type: "index",
          title: "排名",
          width: 70,
          aligh: "center",
        },
        {
          title: "业务名称",
          key: "business_name",
          minWidth: 100,
        },
        {
          title: "告警数量",
          key: "count",
          width: 100,
        },
      ],
      topAlerts: [
        {
          pp_alert_name: "内存使用过高",
          count: "100",
        },
      ],
      topAlertColumns: [
        {
          type: "index",
          title: "排名",
          width: 70,
          align: "center",
        },
        {
          title: "告警名称",
          key: "pp_alert_name",
          minWidth: 200,
          render: (h, { row }) => {
            return h("div", this.get_pp_alert_name(row));
          },
        },
        {
          title: "发生频率",
          key: "count",
          width: 100,
        },
        {
          title: "",
          key: "action",
          width: 70,
          align: "center",
          render: (h, params) => {
            return h(
              "a",
              {
                // style: {
                //   marginRight: "5px",
                // },
                on: {
                  click: () => {
                    //this.showupdatedrawer(params.row);
                  },
                },
              },
              "详情"
            );
          },
        },
      ],
    };
  },
  methods: {
    dateChangeHandle(date) {
      this.tmval = date;
      //alert(new Date(this.tmval[0]).getTime() + "," + new Date(this.tmval[1]).getTime());
    },
    get_pp_alert_name(fields) {
      return fields["pp_alert_name"];
    },
    get_pp_alert_status(fields) {
      return fields["pp_alert_status"] == "firing" ? "未恢复" : "已解决";
    },
    get_pp_business(fields) {
      return fields["pp_business"];
    },
    get_pp_source(fields) {
      return fields["pp_source"];
    },

    get_pp_object(fields) {
      return fields["pp_object"];
    },
    get_pp_trigger_count(fields) {
      return fields["pp_trigger_count"];
    },
    get_pp_severity(fields) {
      const severity = fields["pp_severity"];
      return this.severitynum[severity];
    },

    timestampToTime(cjsj) {
      var date = new Date(cjsj); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },

    time_str(timestamp) {
      return this.timestampToTime(timestamp);
    },

    get_pp_last_time(fields) {
      const pp_last_time = fields["pp_last_time"];
      return this.time_str(pp_last_time * 1000);
    },

    get_pp_end_time(fields) {
      const pp_end_time = fields["pp_end_time"];
      if (pp_end_time === "0") {
        return "--";
      } else {
        return this.time_str(pp_end_time * 1000);
      }
    },

    get_pp_start_time(fields) {
      const pp_start_time = fields["pp_start_time"];
      return this.time_str(pp_start_time * 1000);
    },

    getCurrentDateRange() {
      const end = new Date();
      const start =  new Date(end.getTime() - 3600 * 1000 * 24 * 7);
      const s = `${start.getFullYear()}-${
        start.getMonth() + 1
      }-${start.getDate()}`;
      const e = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
      return [s, e];
    },
  },
  created() {
    this.getinhibits(this.queryinfo);
  },
};
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
